import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import style from './Login.module.scss';
import { Link } from "react-router-dom";
import { sendApiRequest } from "../../utils/api/sendApiRequest";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../utils/api/URL";
import NavigationMenu from "../../components/NavigationMenu/NavigationMenu";
import Footer from "../../components/Footer/Footer";
import { Button } from "../../ui/Button";


function Login() {
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(false);
    const navigate = useNavigate();
    const initialFormData = { login: '', password: '' }
    const initialFormValid = Object.keys(initialFormData).reduce((acc, key) => {
        acc[key] = true;
        return acc;
    }, {});
    const [formData, setFormData] = useState(initialFormData);
    const [formValid, setFormValid] = useState(initialFormValid);
    const { t } = useTranslation();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        handleValidateForm(name, value);
    }

    const handleValidateForm = (name, value) => {
        let nameVerification = false;
        let regex;
        if (name === 'login') {
            regex = /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|[a-zA-Z0-9._-]{3,})$/;
            // regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        } else if (name === 'password') {
            regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        }

        if (value.trim() !== '' && regex.test(value)) {
            nameVerification = true;
        } else {
            nameVerification = false;
        }

        if (nameVerification) {
            setFormValid({ ...formValid, [name]: true });
        } else {
            setFormValid({ ...formValid, [name]: false });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let isError = false;

        Object.entries(formData).forEach(([key, value]) => {
            if (value.trim() === '' && !isError) {
                alert(t('page.RegistrationForm.alert.empty_lines'));
                isError = true;
            }
        });

        Object.keys(formValid).forEach((el) => {
            if (formValid[el] === false && !isError) {
                alert(t('page.RegistrationForm.alert.incorrectly_filled'));
                isError = true;
            }
        });
        if (!isError) {
            const data = await sendApiRequest(BASE_URL + `/login`, 'POST', 'application/json', formData, formValid, e);
            if (data && data.auth) {
                setToken(data);
            } else if (data && !data.auth) {
                alert(t('page.Login.alert.auth_false'));
            }
        }
    }

    useEffect(() => {
        if (token && token.auth) {
            const decoded = jwtDecode(token.accessToken);
            localStorage.setItem('accessToken', token.accessToken);
            localStorage.setItem('refreshToken', token.refreshToken);
            navigate(`/${decoded.role}`, { replace: true });
        }
    }, [token]);

    return (
        <div className={style.wrapper}>
            <NavigationMenu to={'/'} page={t('components.NavigationMenu.Login')} />
            <main className={style.login}>
                {loading
                    ? (<div>Загрузка</div>)
                    : (<div className={style.container}>
                        <div className={style.border}>
                            <h2 className={style.title}>Для входа введите логин и пароль</h2>
                            <form className={style.form}>
                                <div className={formValid.login ? style.block__input : `${style.block__input} ${style.block__input__error}`}>
                                    <input type='text' name='login' value={formData.login} onChange={handleChange} placeholder='Логин' />
                                </div>
                                <div className={formValid.password ? style.block__input : `${style.block__input} ${style.block__input__error}`}>
                                    <input type='text' name='password' value={formData.password} onChange={handleChange} placeholder='Пароль' />
                                </div>
                                <Button
                                    styles={{
                                        display: 'block', margin: '40px auto 0', width: '250px', boxShadow: ' 0px 0px 20px 5px rgba(171, 171, 171, 0.2)'
                                    }}
                                    onClick={handleSubmit}
                                    text={'Войти'}
                                />
                            </form>
                            <div className={style.line} />
                            <p className={style.text__registration}>Если ранее не пользовались сервисом, перейдите на страницу регистрации</p>
                            <Link to={'/registration/customer'} className={style.link}>Зарегистрироваться</Link>
                        </div>
                    </div>)}
            </main>
            <Footer />
        </div>
    )
}

export default Login;